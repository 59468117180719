// ----------------------------------------------------------------------

import { Theme } from '@mui/material';

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingTop: 10,
          paddingBottom: 10,
          minWidth: 44,
          color: theme.palette.secondary.lighter,
          borderRadius: '10px 0',
          fontWeight: 500,
          textTransform: 'initial',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[0],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            // backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            // backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            // backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
